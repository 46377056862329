import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Title = styled.h2`
  font-size: 4em;
  text-align: center;
`
const Subtitle = styled.h3`
  font-size: 3em;
  font-weight: normal;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Title>404 error</Title>
    <Subtitle>Seems like you're lost</Subtitle>
  </Layout>
)

export default NotFoundPage
